//
//
//
//
//
//

import noBoard from '@/components/shared/noBoard';

export default {
  components: {
    noBoard,
  },

};
